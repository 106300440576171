export const hotelUseTypeList =[
    {
        label:'综合',
        value: 1
    },{
        label:'宴请',
        value: 2
    },{
        label:'住宿',
        value: 3
    },{
        label:'会议',
        value: 4
    },
]
export const hotelUseTypeObj ={
    1:'综合',
    2:'宴请',
    3:'住宿',
    4:'会议',
}
export const roomTypeList = [
    {
        label:'单人间',
        value: 1
    },{
        label:'双人间',
        value: 2
    }
]
export const roomTypeObj = {
    1:'单人间',
    2:'双人间'
}

export const roomStatusObj ={
    0:'启用',
    1:'停用',
    2:'待分配',
    3:'已分配',
    4:'已预留',
}