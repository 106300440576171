<template>
  <custom-dialog :visible.sync="visible" :title="title" width="1124px" @close="colseDialog" @submit="submit">
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="asssign-box">
          <div class="title">房间信息</div>
          <div class="box-body">
            <el-form :model="editForm" label-width="80px" :rules="rules" ref="editHotelForm">
              <el-form-item label="所属酒店" prop="compName">
                <el-select v-model="editForm.hotelId" size="small" class="block-select" placeholder="请选择所属酒店"
                           @change="hotelChange">
                  <el-option
                      v-for="item in hotelList"
                      :key="item.hotelId"
                      :label="item.hotelName"
                      :value="item.hotelId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="房间楼层" prop="roomFloor">
                <el-input size="small" v-model="editForm.roomFloor" placeholder="请输入房间楼层" @blur="findRoomInfo"></el-input>
              </el-form-item>
              <el-form-item label="房间编号" prop="bedId">
                <el-select v-model="editForm.bedId" size="small" class="block-select" placeholder="请选择房间编号-床位号">
                  <el-option
                      v-for="(item,index) in roomInfoList"
                      :key="index"
                      :label="`${item.roomNo}-${item.bedNo}`"
                      :value="item.bedId">
                  </el-option>
                </el-select>
              </el-form-item>
<!--              <el-form-item label="床位号" prop="bedId">-->
<!--                <el-input size="small" v-model="editForm.bedId" placeholder="请输入床位"></el-input>-->
<!--              </el-form-item>-->
            </el-form>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="asssign-box">
          <div class="title">待分配人员</div>
          <div class="box-body">
            <el-form :model="searchForm" label-width="80px" :inline="true" ref="searchRoomForm">
              <el-form-item label="所属机构">
                <el-cascader
                    size="small"
                    clearable
                    v-model="orgaIdArr"
                    :options="copanyListInfo"
                    popper-class="custom-cascader"
                    filterable
                    :props="{
              checkStrictly: true,
              expandTrigger: 'hover',
              value: 'orgaPathIds',
            }"
                    @change="cascaderChange"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="证件号" prop="userCardId">
                <el-input size="small" v-model="searchForm.userCardId" placeholder="请输入证件号"></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="userSex">
                <el-select v-model="searchForm.userSex" size="small" placeholder="请选择性别">
                  <el-option
                      :key="0"
                      label="男"
                      :value="0">
                  </el-option>
                  <el-option
                      :key="1"
                      label="女"
                      :value="1">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="compName">
                <el-button size="small" type="primary" @click="searchPerson">搜索</el-button>
              </el-form-item>
            </el-form>
            <el-table
                ref="singleTable"
                :data="searchData"
                highlight-current-row
                header-row-class-name="dialog-header-row"
                @current-change="tableChange"
                style="width: 100%">
              <el-table-column prop="userName" label="姓名"></el-table-column>
              <el-table-column prop="userSex" label="性别">
                <template slot-scope="scope">
                  {{scope.row.userSex == 1?'女':'男'}}
                </template>
              </el-table-column>
              <el-table-column prop="userCardId" label="证件号"></el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import basic from "../../../api/modules/basic";
import {organizationCascader} from "../../../utils/tool";
import accommodation from "../../../api/modules/accommodation";

export default {
  name: "EditAssignmentDialog",
  components: { CustomDialog},
  props: ['visible', 'title', 'item', 'type'],
  data() {
    return {
      editForm: {},
      rules: {},
      searchForm: {},
      searchData: [],
      orgaIdArr:[],
      copanyListInfo: [],
      hotelList:[],
      roomInfoList:[],
      userId: undefined
    }
  },
  mounted() {
    this.getCopanyInfo()
    this.getHotelInfo()
  },
  methods: {
    getHotelInfo(){
      accommodation.getHotelList().then(res =>{
        this.hotelList = res.data
      })
    },
    getCopanyInfo() {
      basic.getOrganizationTree({orgaPid: 0}).then((res) => {
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data, "noProject");
        this.copanyListInfo = data;
      });
    },
    colseDialog() {
      this.$emit('close')
      this.cleanForm()
    },
    submit() {
      this.$refs.editHotelForm.validate((valid) => {
        if (valid) {
          let params = {
            userIds: this.userId,
            bedId: this.editForm.bedId,
            allocType: this.type
          }
          if (this.editForm.reseId) {
            // 木有编辑
            this.$emit('done')
            this.cleanForm()
          } else {
            console.log(params);
          
            accommodation.addAllot(params).then(res => {
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.$emit('done')
              this.cleanForm()
            })
          }
        }
      })
    },
    cleanForm(){
      this.editForm = {}
      this.searchData = []
      this.searchForm = {}
      this.orgaIdArr = []
    },
    searchPerson() {
      accommodation.getAllotUserInfo(this.searchForm).then(res =>{
        this.searchData = res.data
      })
    },
    cascaderChange(val) {
      console.log(val);
      let valLen = val.length;
      this.searchForm.orgaPathIds = val[valLen - 1];
    },
    hotelChange(val) {
      this.findRoomInfo()
    },
    findRoomInfo() {
      let params = {
        hotelId: this.editForm.hotelId,
        roomFloor: this.editForm.roomFloor,
        type: this.type
      }
      accommodation.getAllotRoom(params).then(res => {
        this.roomInfoList = res.data
        // let roomInfoObj = {}
        // res.data.forEach(item =>{
        //   if(roomInfoObj[item.roomNo]){
        //
        //   }
        // })
      })
    },
    tableChange(val){
      console.log('val', val)
      this.userId = val.userId
    }
  }
}
</script>

<style scoped lang="less">
.asssign-box {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  //padding: 10px;
  .title {
    font-size: 16px;
    border-bottom: 1px solid #f0f0f0;
    padding: 10px;
    color: #000000;
  }

  .box-body {
    padding: 10px;
    min-height: 500px;
  }
}

</style>