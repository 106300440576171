<template>
  <div>
    <div class="head-btn">
      <el-row>
        <el-col :span="12">
          <div class="table-title"><i class="iconfont icon-caozuo-caidan"></i>{{title}}&nbsp;</div>
        </el-col>
        <el-col :span="12" class="right" v-if="!noall">
          <slot name="btns"></slot>
          <el-button v-if="!isdel" size="small" type="danger" plain @click="tableDel">删除</el-button>
          <el-button v-if="!noimport" size="small" type="success" @click="tableGetImport">获取模板</el-button>
          <el-upload
              class="upload-demo"
              action=""
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              multiple
              :auto-upload="false"
              :show-file-list="false"
              :limit="1"
              :on-change="tableImport">
            <el-button v-if="!noimport" size="small" type="success">导入</el-button>
          </el-upload>
          <el-button v-if="!noexport" size="small" type="primary" @click="tableExport">导出</el-button>
          <el-button v-if="!isadd" size="small" type="primary" plain @click="tableAdd">新增</el-button>
          <el-button v-if="issh" size="small" type="primary" plain @click="tableexamine">审核</el-button>
          <el-button v-if="report" size="small" type="primary" plain @click="tableReport">报道</el-button>
          <el-button v-if="iscode" size="small" type="warning" plain @click="downCode">二维码下载</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table
        :data="data"
        border
        header-row-class-name="custom-header-row"
        style="width: 100%"
        @selection-change="handleSelectionChange">
      <slot></slot>
    </el-table>
    <el-pagination
        class='pag-right'
        v-if="!pagesc"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "CustomTables",
  props:['data','currentPage','total','title','noimport','noexport','noall','isdel','iscode','isadd','issh','pagesc','report'],
  data(){
    return {
    }
  },
  methods: {
    handleSizeChange(item){
      this.$emit('handleSizeChange', item)
    },
    handleCurrentChange(item){
      this.$emit('handleCurrentChange', item)
    },
    tableDel(){
      this.$emit('tableDel')
    },
    tableexamine(){
      this.$emit('tableexamine')
    },
    tableReport(){
      this.$emit('tableReport')
    },
    tableImport(file){
      this.$emit('tableImport',file)
    },
    tableGetImport(){
      this.$emit('tableGetImport')
    },
    tableExport(){
      this.$emit('tableExport')
    },
    handleSelectionChange(val){
      this.$emit('selectionChange', val)
    },
    tableAdd(){
      this.$emit('tableAdd')
    },
    downCode(){
      this.$emit('downCode')
    }
  }
}
</script>

<style scoped lang="less">
.pag-right{
  text-align: right;
  margin-top: 20px;
}
.head-btn{
  padding: 10px 20px;
  border: 1px solid #f0f0f0;
  border-bottom:none ;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .table-title{
    font-weight: bolder;
    position: relative;
    top: 6px;
    .iconfont{
      margin-right: 5px;
    }
  }
}
.right{
  display: flex;
  justify-content: right;
  .el-button{
    margin-left:15px ;
  }
}

</style>