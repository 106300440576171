import api from '../httpServer'
import config from '../config'

const basic ={
    /*
    *组织机构树
    * */
    getOrganizationTree(data) {
        return api.get(`${config.headerUrl}organization/GetOrganizationTree`,data)
    },
    /*
    * 公司
    * */
    // 新增公司
    newCompany(data) {
        return api.post(`${config.headerUrl}company/newCompany`,data)
    },
    //删除公司
    delCompany(data) {
        return api.post(`${config.headerUrl}company/delCompany`,data)
    },
    // 编辑公司
    updateCompany(data) {
        return api.post(`${config.headerUrl}company/updateCompany`,data)
    },
    // 查询公司详情
    selectCompanyDetail(data) {
        return api.get(`${config.headerUrl}company/selectCompanyDetail`,data)
    },
    // 查询公司列表 分页
    selectCompanyList(data) {
        return api.get(`${config.headerUrl}company/selectCompanyList`,data)
    },
    // 查询公司列表
    getCompanyCheckList(data) {
        return api.get(`${config.headerUrl}company/getCompanyCheckList`,data)
    },
    // 省市区懒加载  省：{pid：0}   市 {pid: 省id}
    getAreaList(data) {
        return api.get(`${config.headerUrl}basicSet/GetAreaList`,data)
    },
    /*
    * 项目信息
    * */
    // 添加项目信息
    addProject(data) {
        return api.post(`${config.headerUrl}basicSet/AddProject`,data)
    },
    // 删除项目信息
    delProject(data) {
        return api.post(`${config.headerUrl}basicSet/delProject`,data)
    },
    // 编辑项目信息
    updateProject(data) {
        return api.post(`${config.headerUrl}basicSet/updateProject`,data)
    },
    // 获取项目详情
    getProjectById(data) {
        return api.get(`${config.headerUrl}basicSet/GetProjectById`,data)
    },
    // 项目信息的分页查询
    getProjectListByPage(data) {
        return api.get(`${config.headerUrl}basicSet/GetProjectListByPage`,data)
    },
    // 获取项目选择列表
    getProjectList(data) {
        return api.get(`${config.headerUrl}basicSet/GetProjectList`,data)
    },
    /*
   * 人员信息
   * */
    // 添加操作员信息
    addOperator(data) {
        return api.post(`${config.headerUrl}basicSet/AddMeetingUserByAdmin`,data)
    },
    // 编辑操作员
    editOperator(data) {
        return api.post(`${config.headerUrl}basicSet/UpdateMeetingUserInfo`,data)
    },
    // 删除操作员信息
    deleteOperator(data) {
        return api.del(`${config.headerUrl}basicSet/deleteMeetingUserInfo/${data.userIds}`,data)
    },
    // 获取操作员详情
    getOperatorById(data) {
        return api.get(`${config.headerUrl}basicSet/meetingUserInfoDetial`,data)
    },
    //分页获取操作员列表
    getOperatorListByPage(data) {
        return api.get(`${config.headerUrl}basicSet/getMeetingUserInfoPageList`,data)
    },
    //添加随行人员
    AddFollowUserInfo(data) {
        return api.post(`${config.headerUrl}basicSet/AddFollowUserInfo`,data)
    },
    // 获取操作员列表
    getOperatorList(data) {
        return api.get(`${config.headerUrl}basicSet/GetOperatorList`,data)
    },
    /*
    * 自检项目，按设备类型添加
    * */
    // 新增信息
    addCheckItemInfo(data) {
        return api.post(`${config.headerUrl}selfCheckItem/addInfo`,data)
    },
    // 删除信息
    deleteCheckItemInfo(data) {
        return api.post(`${config.headerUrl}selfCheckItem/deleteInfo`,data)
    },
    // 修改信息
    editCheckItemInfo(data) {
        return api.post(`${config.headerUrl}selfCheckItem/editInfo`,data)
    },
    // 获取详情
    getCheckItemDetail(data) {
        return api.get(`${config.headerUrl}selfCheckItem/getDetail`,data)
    },
    // 获取列表
    getCheckItemList(data) {
        return api.get(`${config.headerUrl}selfCheckItem/getList`,data)
    },
    /*
   * 维保项目，按设备类型添加
   * */
    // 新增信息
    addMaintenanInfo(data) {
        return api.post(`${config.headerUrl}maintenanItem/addInfo`,data)
    },
    // 删除信息
    deleteMaintenanInfo(data) {
        return api.post(`${config.headerUrl}maintenanItem/deleteInfo`,data)
    },
    // 修改信息
    editMaintenanInfo(data) {
        return api.post(`${config.headerUrl}maintenanItem/editInfo`,data)
    },
    // 获取详情
    getMaintenanDetail(data) {
        return api.get(`${config.headerUrl}maintenanItem/getDetail`,data)
    },
    // 获取列表
    getMaintenanList(data) {
        return api.get(`${config.headerUrl}maintenanItem/getList`,data)
    },
    /*
  * 系统设置 --基础设置
  * */
    // 修改设置
    editSysConfigInfo(data) {
        return api.post(`${config.headerUrl}sysConfig/editInfo`,data)
    },
    // 获取详情
    getSysConfigInfo(data) {
        return api.get(`${config.headerUrl}sysConfig/getDetail`,data)
    },
    /*
    * 系统设置 --类型代码设置
    * */
    // 新增信息
    addEquipTypeInfo(data) {
        return api.post(`${config.headerUrl}equipType/addInfo`,data)
    },
    // 删除信息
    deleteEquipTypeInfo(data) {
        return api.post(`${config.headerUrl}equipType/deleteInfo`,data)
    },
    // 修改信息
    editEquipTypeInfo(data) {
        return api.post(`${config.headerUrl}equipType/editInfo`,data)
    },
    // 获取详情
    getEquipTypeDetail(data) {
        return api.get(`${config.headerUrl}equipType/getDetail`,data)
    },
    // 获取列表
    getEquipTypeList(data) {
        return api.get(`${config.headerUrl}equipType/getList`,data)
    },
    //公司信息导出
    getCompanyRecExport(data) {
        return api.down(`${config.headerUrl}company/CompanyRecExport`,data)
    },
    //会议信息导出
    getMeetingInfoExport(data) {
        return api.down(`${config.headerUrl}meeting/getMeetingInfoExport`,data)
    },
    //项目信息导出
    getProjectRecExport(data) {
        return api.down(`${config.headerUrl}basicSet/ProjectRecExport`,data)
    },
     //进场验收导出
     getEquipmentsRecExport(data) {
        return api.down(`${config.headerUrl}device/EquipmentsRecExport`,data)
    },
     //安拆信息导出
     getRectificationRecExport(data) {
        return api.down(`${config.headerUrl}device/RectificationRecExport`,data)
    },
    //设备操作人员导出
    getEquipmentRecExport(data) {
        return api.down(`${config.headerUrl}device/EquipmentRecExport`,data)
    },
     //设备自检导出
     getSelfRecExport(data) {
        return api.down(`${config.headerUrl}selfCheck/SelfRecExport`,data)
    },
    //设备巡检导出
    getInspectionRecExport(data) {
        return api.down(`${config.headerUrl}onSiteInspection/InspectionRecExport`,data)
    },
    //月检记录导出
    getMonthRecExport(data) {
        return api.down(`${config.headerUrl}monthCheck/MonthRecExport`,data)
    },
    //维保记录导出
    getEquipmentsRecExports(data) {
        return api.down(`${config.headerUrl}device/EquipmentsRecExport`,data)
    },
    //整改记录导出
    getRectificationRecExports(data) {
        return api.down(`${config.headerUrl}deviceRecord/RectificationRecExport`,data)
    },
     //数据字典导出
     getDictionaryRecExport(data) {
        return api.down(`${config.headerUrl}dictionary/DictionaryRecExport`,data)
    },
    // 自有设备台账记录导出
    getOwnEquipRecExport(data) {
        return api.down(`${config.headerUrl}device/OwnEquipRecExport`,data)
    },
    // 导出租赁设备台账
    leasedEquipRecExport(data) {
        return api.down(`${config.headerUrl}report/LeasedEquipRecExport`,data)
    },
    // 导出自有机械设备台账
    selfEquipRecExport(data) {
        return api.down(`${config.headerUrl}report/SelfEquipRecExport`,data)
    },
    // 特种设备台账记录导出
    specialEquipRecExport(data) {
        return api.down(`${config.headerUrl}report/SpecialEquipRecExport`,data)
    },
    //协作队伍机械设备台帐
    bringEquipRecExport(data) {
        return api.down(`${config.headerUrl}report/BringEquipRecExport`,data)
    },
    //告警台账记录导出
    getAlarmLedgerRecExport(data) {
        return api.down(`${config.headerUrl}deviceRecord/AlarmLedgerRecExport`,data)
    },
    /*
    * 设备进场验收导入
    * */
    // 进场验收导入
    importEquipments(data) {
        return api.upFile(`${config.headerUrl}device/importEquipments`,data)
    },
    // 获取设备导入模版
    exportEquipTemp(data) {
        return api.down(`${config.headerUrl}device/exportEquipTemp`,data)
    },
     // 获取房间导入模版
     importRoomTemp(data) {
        return api.down(`${config.headerUrl}room/importRoomTemp`,data)
    },
    // 进场验收导入查询
    getEquipmentListByImport(data) {
        return api.get(`${config.headerUrl}device/GetEquipmentListByImport`,data)
    },
    // 进场验收导入--删除
    deleteEquipmentsList(data) {
        return api.post(`${config.headerUrl}device/deleteEquipmentsList`,data)
    },
    // 保存进场验收导入
    saveImportEquipments(data) {
        return api.post(`${config.headerUrl}device/saveImportEquipments`,data)
    },
    // 进场验收导入--编辑
    updateImportEquip(data) {
        return api.post(`${config.headerUrl}device/updateImportEquip`,data)
    },
    //审核
    check(data) {
        return api.post(`${config.headerUrl}basicSet/check`,data)
    },
    //订单信息
    getOrderInfo(data) {
        return api.get(`${config.headerUrl}order/getOrderInfo`,data)
    },
     //所属项目查询下拉框列表
     getProjectList(data) {
        return api.get(`${config.headerUrl}login/getProjectList`,data)
    },
    //随行行人员列表
    getFollowUserList(data) {
        return api.get(`${config.headerUrl}basicSet/getFollowUserList/${data}`)
    },
     //添加随行人员
     AddFollowUserInfo(data) {
        return api.post(`${config.headerUrl}basicSet/AddFollowUserInfo`,data)
    },
     //删除随行人员
     deleteFollowUser(data) {
        return api.post(`${config.headerUrl}basicSet/deleteFollowUser`,data)
    },
      //编辑操作人员
      UpdateMeetingUserInfo(data) {
        return api.post(`${config.headerUrl}basicSet/UpdateMeetingUserInfo`,data)
    },
    //分页查询人员报道列表
    getRoomByPage(data) {
        return api.get(`${config.headerUrl}user/report/getUserPage`, data)
    },
      //报道
      userReport(data) {
        return api.post(`${config.headerUrl}user/report/userReport`,data)
    },
  //导入
    redis(data) {
        return api.upFile(`${config.headerUrl}room/redis`,data)
    },
    //房间信息缓存列表
    redisList(data) {
        return api.get(`${config.headerUrl}room/redisList`, data)
    },
    //对缓存中的数据继续修改
    updateRedis(data) {
        return api.post(`${config.headerUrl}room/updateRedis`,data)
    },
    //房间信息导入到数据库
    roomData(data) {
        return api.get(`${config.headerUrl}room/data`,data)
    },
      //.删除缓存记录
      deleteFollowUser(data) {
        return api.del(`${config.headerUrl}room/delRedis/${data}`)
    },
}

export default basic