<template>
  <el-dialog
      class="custom-dialog"
      :title="title"
      :visible.sync="visible"
      :width="width || '60%'"
      append-to-body
      :before-close="handleClose">
    <div slot="title" class="dialog-title">{{title}} &nbsp;</div>
    <slot></slot>
    <div slot="footer" class="dialog-footer" v-if="!isFooter">
      <el-button class="dialog-btn" size="medium" @click="handleClose">取 消</el-button>
      <el-button class="dialog-btn"  size="medium"  type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ['visible', 'width','title','isFooter'],
  name: "CustomDialog",
  methods:{
    handleClose(){
      this.$emit('close')
    },
    submit(){
      this.$emit('submit')
    }
  }
}
</script>

<style scoped lang="less">
.dialog-title{
  font-weight: bolder;
}
.dialog-btn{
  padding: 10px 35px;
}


</style>