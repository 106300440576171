<template>
  <div class="search-main">
    <el-row class="search-btn">
      <el-col :span="12">
        <div class="search-title"><i class="el-icon-search"></i> 条件筛选</div>
      </el-col>
      <el-col :span="12" class="btn-main">
        <el-button size="small" @click="reset">重置</el-button>
        <el-button size="small" type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <div class="condition-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchHead",
  methods:{
    reset(){
      this.$emit('reset')
    },
    search(){
      this.$emit('search')
    }
  }
}
</script>

<style scoped lang="less">
.search-main{
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-bottom: 20px;
  .search-btn{
    padding: 5px 20px;
  }
  .search-title{
    position: relative;
    top: 5px;
    font-weight: 600;
  }
}
.btn-main{
  text-align: right;
}
.condition-main{
  border-top: 1px solid #f0f0f0 ;
  padding:15px 20px 0px;
}

</style>