/*
* 转换时间的
*
* */
export function turnTime(value) {
    let date = new Date(value);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
}
/*
* 类型判断
* */
export const checkType = val => Object.prototype.toString.call(val).slice(8, -1)

/*
* 判断价格
* */
export function priceReg(val){
    let reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
    return reg.test(val)
}

/*
* 组织机构树--生成为cascader可用
* return {value, label, children}
* */
export function organizationCascader(dataList, noProject){
   return dataList.map((item) =>{
        let get = (data) =>{
            return  data.map(child =>{
                let children
                if(noProject && child.orgaLevel >= 1) {
                    children = null
                }else {
                    children = child.children && child.children.length > 0 ? get(child.children) : null
                }
                return {
                    value: child.orgaId,
                    label: child.orgaName,
                    orgaPathIds: child.orgaPathIds,
                    level: child.orgaLevel,
                    children: children
                }
            })
        }

       let childrenFirst
       if (noProject && item.orgaLevel >= 1) {
           childrenFirst = null
       }else {
           childrenFirst = item.children && item.children.length > 0 ? get(item.children) : null
       }
        return {
            value: item.orgaId,
            label:item.orgaName,
            orgaPathIds: item.orgaPathIds,
            level: item.orgaLevel,
            children: childrenFirst
        }
    })
}

/*
* 准操作项目--生成为cascader可用
* return {value, label, children}
* */
export function equipmentTreeCascader(dataList){
    return dataList.map((item) =>{
        let get = (data) =>{
            return  data.map(child =>{
                return {
                    value: child.operItemId,
                    label: child.operItemName,
                    code: item.operItemCode,
                    children: child.child && child.child.length > 0 ? get(child.child) : null
                }
            })
        }
        return {
            value: item.operTypeId,
            label:item.operTypeName,
            children: item.child && item.child.length > 0 ? get(item.child) : null
        }
    })
}
/*
* 下载，导出文档
* */
export function downloadFile (res) {
    const blob = new Blob([res.data]);
    const a = document.createElement('a');
    const href = window.URL.createObjectURL(blob); // 下载的链接
    a.href = href;
    a.download = decodeURI(
        res.headers['content-disposition'].split(';')[1].split('=')[1]
    ); // 获取后台设置的文件名称
    document.body.appendChild(a);
    a.click(); // 点击导出
    document.body.removeChild(a); // 下载完成移除元素
    window.URL.revokeObjectURL(href); // 释放掉blob对象
    // return file
}