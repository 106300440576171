<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="预留机构">
          <el-select v-model="searchForm.projId" size="small" class="block-select" placeholder="请选择预留机构">
            <el-option
                v-for="item in projectList"
                :key="item.projId"
                :label="item.projName"
                :value="item.projId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间编号">
          <el-input
              size="small"
              clearable
              v-model="searchForm.roomNo"
              placeholder="请输房间编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件号">
          <el-input
              size="small"
              clearable
              v-model="searchForm.userCardId"
              placeholder="请输证件号"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker
              size="small"
              v-model="searchForm.startTime"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker
              size="small"
              v-model="searchForm.endTime"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
        :data="tableData"
        :total="total"
        isdel="true"
        isadd="true"
        noimport="true"
        title="房间分配列表"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @tableExport="tableExport"
        @tableDel="tableDel"
        @tableAdd="tableAdd">
      <template #btns>
        <el-button size="small" type="success" @click="tableAdd(1)">手动分配</el-button>
        <el-button size="small" type="warning" @click="tableAdd(2)">预留分配</el-button>
      </template>
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column prop="userName" label="姓名" width="180"></el-table-column>
      <el-table-column prop="userCardId" label="证件号"></el-table-column>
      <el-table-column prop="bedNo" label="床位号"></el-table-column>
      <el-table-column prop="roomNo" label="房间编号"></el-table-column>
      <el-table-column prop="roomTypeName" label="房间类别"></el-table-column>
      <el-table-column prop="roomFloor" label="楼层"></el-table-column>
      <el-table-column prop="hotelName" label="所属酒店"></el-table-column>
      <el-table-column prop="roomBedCount" label="床位数"></el-table-column>
      <el-table-column prop="createTime" label="分配时间"></el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
<!--          <span class="btn-icon" @click="editItem(scope.row)">详情</span>-->
          <span class="btn-icon" @click="delItem(scope.row)">删除</span>
        </template>
      </el-table-column>
    </custom-tables>
    <edit-assignment-dialog :visible.sync="showDialog"
                            :title="dialogTitle"
                            :item="item"
                            :type="addType"
                            @close="showDialog = false"
                            @done="editDone"></edit-assignment-dialog>
  </div>
</template>

<script>
import basic from "../../../api/modules/basic";
import accommodation from "../../../api/modules/accommodation";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import {downloadFile} from "../../../utils/tool";
import EditAssignmentDialog from "../../../components/page/accommodation/EditAssignmentDialog";
import {roomStatusObj, roomTypeObj} from "../../../utils/hotelType";

export default {
  name: "roomAssignment",
  components: {EditAssignmentDialog, SearchHead, CustomTables},
  data() {
    return {
      dialogTitle: "新增酒店",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      item: {},
      areaParentList: [],
      projectList:[],
      addType:1
    };
  },
  mounted() {
    this.getInfo();
    this.getProjectList()
  },
  methods: {
    getProjectList() {
      basic.getProjectList().then(res => {
        this.projectList = res.data
      })
    },
    getInfo() {
      accommodation.getAllotByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        let data = res.data.records
        this.tableData = []
        data.forEach(item =>{
          this.tableData.push({
            ...item,
            roomTypeName: roomTypeObj[item.roomType],
          })
        })
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.pageNum = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.getInfo();
    },
    headReset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    headSearch() {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    tableDel() {
    },
    //导出
    tableExport() {
      let param = {
        pageNum: this.searchForm.pageNum,
        pageSize: this.searchForm.pageSize,
      };
      basic.getCompanyRecExport(param).then((res) => {
        downloadFile(res)
      }).catch(err => {
        console.log(err, 'err');
      })
    },
    //格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    tableAdd(type) {
      this.item = {};
      this.dialogTitle = type==1? "手动分配房间信息":"预留分配房间信息";
      this.addType = type
      this.showDialog = true;
    },
    selectionChange(val) {
      console.log(val);
    },
    editItem(item) {
      console.log(item);
      this.item = item;
      this.dialogTitle = "编辑房间分配信息";
      this.showDialog = true;
    },
    delItem(item) {
      this.$confirm(`此操作将永久删除该房间分配信息, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        accommodation.delAllot({allocId: item.allocId}).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
  },
};
</script>

<style scoped></style>
