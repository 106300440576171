import api from '../httpServer'
import config from '../config'

const accommodation ={
    /*
   * 住宿管理-酒店管理
   * */
    // 新增
    addHotel(data) {
        return api.post(`${config.headerUrl}hotel/addHotel`,data)
    },
    // 删除
    delHotel(data) {
        return api.get(`${config.headerUrl}hotel/delHotel/${data.hotelId}`,data)
    },
    // 分页查询酒店管理列表
    getHotelByPage(data) {
        return api.get(`${config.headerUrl}hotel/getHotelByPage`,data)
    },
    // 分页查询酒店管理列表
    getHotelAnnex(data) {
        return api.get(`${config.headerUrl}hotel/getHotelAnnex/${data.hotelId}`,data)
    },
    /*
  * 住宿管理-房间管理
  * */
    // 新增
    addRoom(data) {
        return api.post(`${config.headerUrl}room/addRoom`,data)
    },
    // 删除
    delRoom(data) {
        return api.get(`${config.headerUrl}room/delRoom/${data.roomId}`,data)
    },
    // 分页查询房间管理列表
    getRoomByPage(data) {
        return api.get(`${config.headerUrl}room/getRoomByPage`,data)
    },
    // 所属酒店查询下拉框列表
    getHotelList(data) {
        return api.get(`${config.headerUrl}room/getHotelList`,data)
    },
    // 导出房间管理记录
    exportRoom(data) {
        return api.down(`${config.headerUrl}room/exportRoom`,data)
    },
    /*
     * 住宿管理-房间预留
     * */
    // 新增
    addRoomReserve(data) {
        return api.post(`${config.headerUrl}room/reserve/addRoomReserve`,data)
    },
    // 删除
    delRoomReserve(data) {
        return api.get(`${config.headerUrl}room/reserve/delRoomReserve/${data.reseId}`,data)
    },
    // 分页查询房间预留列表
    getReserveByPage(data) {
        return api.get(`${config.headerUrl}room/reserve/getReserveByPage`,data)
    },
    // 新增-房间预留信息查询
    getAddRoomReserve(data) {
        return api.get(`${config.headerUrl}room/reserve/getAddRoomReserve`,data)
    },
    /*
    * 住宿管理-房间分配
    * */
    // 手动分配-预留分配
    addAllot(data) {
        return api.post(`${config.headerUrl}room/allot/addAllot`,data)
    },
    // 删除
    delAllot(data) {
        return api.get(`${config.headerUrl}room/allot/delAllot/${data.allocId}`,data)
    },

    // 导出房间分配记录
    exportAllot(data) {
        return api.down(`${config.headerUrl}room/allot/exportAllot`,data)
    },
    // 分页查询房间分配列表
    getAllotByPage(data) {
        return api.get(`${config.headerUrl}room/allot/getAllotByPage`,data)
    },
    // 手动分配-预留分配 查询房间信息
    getAllotRoom(data) {
        return api.get(`${config.headerUrl}room/allot/getAllotRoom`,data)
    },
    // 手动分配-预留分配查询待分配人员信息
    getAllotUserInfo(data) {
        return api.get(`${config.headerUrl}room/allot/getAllotUserInfo`,data)
    },
}
export default accommodation